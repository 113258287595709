import { type FirebaseOptions, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth, onAuthStateChanged } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

export const firebaseConfig = JSON.parse(
  import.meta.env.VITE_FIREBASE_CONFIG as string,
) as FirebaseOptions;

export const fb = initializeApp(firebaseConfig);

export const fb_storage = getStorage(fb);
export const fb_auth = getAuth(fb);
export const on_auth_state_changed = onAuthStateChanged;

if (import.meta.env.DEV) {
  connectAuthEmulator(fb_auth, 'http://localhost:9099');
  connectStorageEmulator(fb_storage, 'localhost', 9199);
}
